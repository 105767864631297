<style >
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.action-modal {
  z-index: 10;
  position: absolute;
  margin-top: -10px;
  background: white;
}
ul {
  list-style-type: none;
}
.modal-button:hover {
  background-color: rgb(214 208 201) !important;
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.action-list:hover {
  background-color: #d7d9f8;
  transition: 0.7s;
  color: black;
  border-radius: 5%;
}
#search-icon-top-fixes .adv_seach_icon{
  top:-1.7rem !important;
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body " id="search-icon-top-fixes">
      <adv-search
        @search="advSearch"
        :is_date_range="false"
        :is_service="true"
        :is_admin_sp_location="true"
        :is_status="false"
        :is_location="false"
        :is_archival="true"
        :is_company1="true"
      />
      <!-- <div class="row" v-if="AppType">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by appointment no or name"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-info" type="button" @click="search()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <enrollmentDetailModal
        :key="key"
        v-if="enrollmentDetailModalShow"
        v-on:closemodal="closeEnrollmentModal"
        :loading-data="loadingData"
        :has-data="hasData"
        :show-modal="enrollmentDetailModalShow"
        :enrollment-data="enrollmentData"
        :index-num="currentIndex"
      ></enrollmentDetailModal>

      <div class="row" v-if="appoinments.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <download-excel
            class="btn btn-primary ml-1"
            :data="excelreports"
            :header="header"
            name="archival-expiration-report.xls"
            :fields="json_fields"
          >
            <i class="test-white fa fa-download"></i> Download Spreadsheet
          </download-excel>
        </div>
      </div>

      <hr v-if="appoinments.length > 0 && !getLoadingData && AppType" />
      <div class="row" v-if="appoinments.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <v-table
            :columns="columns"
            v-if="AppType && DashType == 'appointment_list'"
          >
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6 style="font-size: 13px; font-weight: 400">
                  {{ item.enroll_date }}
                </h6>
              </td>

              <td>
                <a
                  href="javascript:void;"
                  @click="openEnrollmentDetail(item.id, index)"
                >
                  {{ item.id }}</a
                >
              </td>
              <td>
                {{ item.applicant }}
              </td>
              <td>
                {{ item.tcn }}
              </td>
              <td>
                {{ item.company_name }}
              </td>

              <td>
                <b>
                  {{ item.service_name }}
                  <span v-if="item.is_additional"
                    >,{{ item.is_additional }}</span
                  >
                </b>
                <br />
                {{ item.originating_location_name }}
              </td>

              <td>
                {{ item.archival_name }}
              </td>
              <td :style="{color:new Date(item.archival_expiry_date)<new Date()?'red':'black' }">
                {{ dateFormat(item.archival_expiry_date) }}
              </td>
              <td>
                <button
                  class="text-center modal-button modal-box"
                  @click="IndexClick(index)"
                  v-if="item.enrollment_id != ''"
                  style="background-color: rgb(243, 241, 239); padding:0.5rem 0.5rem 0.4rem 0.4rem;"
                >
                  <i class="las la-ellipsis-v modal-box"></i>
                </button>
                <div
                  class="action-modal"
                  v-show="showActionButton === index"
                  style="
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 5%;
                    font-size:0.8rem;
                  "
                >
                  <ul
                    style="
                      border-radius: 5%;
                      margin: 5px;
                      margin-top: 5px;
                      margin-bottom: 5px;
                    "
                  >
                    <!-- <li
                      class="action-list"
                      style="cursor: pointer; padding: 5px"
                      @click="edit(item.enrollment_id, index)"
                    >
                      <button>Retrieve</button>
                    </li>
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                      @click="
                        assignEnrollmentCompany(item.enrollment_id, index)
                      "
                    >
                      <button>Assign Company</button>
                    </li>
                    <hr /> -->
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button
                        @click="
                          changeEnrollmentArchive(
                            item.enrollment_id,
                            item.tcn,
                            false,
                            item.client_slug,
                          )
                        "
                      >
                        Renew Archival
                      </button>
                    </li>

                    <!-- <hr
                      v-if="
                        item.enrollment_id != null &&
                          item.enrollment_id != '' &&
                          item.has_invoice
                      "
                    />
                    <li
                      v-if="
                        item.enrollment_id != null &&
                          item.enrollment_id != '' &&
                          item.has_invoice
                      "
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button @click="openInvoice(item.enrollment_id)">
                        Invoice
                      </button>
                    </li> -->
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button
                        @click="removeArchival(item.id, item.tcn)"
                      >
                        Remove Archive
                      </button>
                    </li> 
                  </ul>
                </div>
           
              </td>
            </tr>
          </v-table>

          <v-table :columns="columns" v-else-if="DashType == 'today'">
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.client }}
              </td>
            </tr>
          </v-table>

          <v-table :columns="columns" v-else>
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6 style="font-size: 12px">
                  {{ item.schedule_date | dateFormat }}
                </h6>
                <h6 style="font-size: 12px">
                  {{ item.start_time | timeFormat(item.schedule_date) }}
                </h6>
              </td>
              <td class="text-right">
                {{ item.id }}
              </td>
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.client }}
              </td>
            
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="appoinments.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no archived enrollment(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_EXPIRY_ARCHIVAL_APPOINTMENT,
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_ENROLLMENT,
  ENROLLMENT_LOGS
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import enrollmentDetailModal from "@/components/frontend/modal/enrollmentdetail";
import AdvSearch from "@/includes/search/AdvSearch";
import { REMOVE_ARCHIVE } from "../../../../../core/services/store/actions.type";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vDataLoader,
    AdvSearch,
    enrollmentDetailModal,
    downloadExcel: JsonExcel
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getServices"])
  },
  props: {
    AppType: {
      type: Boolean,
      default: true
    },
    DashType: {
      type: String,
      default: "appointment_list"
    }
  },

  data() {
    return {
      enrollmentData: {},
      enrollmentDetailModalShow: false,
      appointmentDetailModalShow: false,
      json_fields: {
        "Enroll Date": {
          field: "enroll_date",
          callback: value => {
            return this.dateFormat(value);
          }
        },
        "Applicant Name": "applicant",
        TCN: "tcn",
        Location: "client_name",
        Company:"company_name",
        Service: "service_name",
        Archival: "archival_name",
        "Expires On": {
          field: "archival_expiry_date",
          callback: value => {
            return this.dateFormat(value);
          }
        }
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      showQrCode: false,
      getLoadingData: false,
      header: [],
      load: true,
      excelreports: [],
      showActionButton: "",
      columns: [
        {
          name: "enroll date",
          width: "",
          label: "Enroll Date",
          class: ""
        },

        {
          name: "enrollment_id",
          width: "",
          label: "Enr No",
          class: ""
        },
        {
          name: "applicant",
          width: "",
          label: "Applicant",
          class: ""
        },
        {
          name: "tcn",
          width: "",
          label: "TCN",
          class: ""
        },
        {
          name: "Company",
          width: "",
          label: "Company",
          class: ""
        },
        {
          name: "Service/location",
          width: "",
          label: "Service/Location",
          class: ""
        },

        {
          name: "archival_name",
          width: "",
          label: "Archival",
          class: ""
        },
        {
          name: "Expires on",
          width: "",
          label: "Expires on",
          class: ""
        },
        {
          name: "Action",
          width: "10%",
          label: "Action",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Archival List",
          route: ""
        }
      ],
      search: {
        service: "all",
        location: "all",
        status: "all",
        payment_status: "all",
        no_of_days: 99999
      },
      appoinments: [],

      selectedAppId: 0,
      appointmentData: {},
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      msg: "",
      search_text: "",
      token: "",
      key: 0
    };
  },

  mounted() {
    if (window.localStorage.getItem("appoitmentsearch") != null) {
      let search = JSON.parse(window.localStorage.getItem("appoitmentsearch"));
      this.search.service = search.service;
      this.search.status = search.status;
      this.search.payment_status = search.payment_status;
      this.search.location = search.service_outlet
        ? search.service_outlet
        : "all";
      this.type = search.currentUser;
      this.$store.dispatch(FETCH_SERVICES, this.search.location);
    } else {
      this.search.location = this.currentUser.u.is_client ? "self" : "all";
      this.$store.dispatch(FETCH_SERVICES, this.search.location);
    }
    this.$store.dispatch(FETCH_CLIENTS);
    if (this.AppType) {
      this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    }
    // this.appData();
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener("click", this.handleDocumentClick);
  },

  methods: {
    removeArchival(enrollment_id, tcn) {
      let data = {
       enrollment_id: enrollment_id
     };
     let message = `You want to remove this Enrollment# ${enrollment_id} \n
     TCN: ${tcn} from archive `;
     this.$swal
       .fire({
         title: "Are you sure?",
         text: message,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes",
         cancelButtonText: "No"
       })
       .then(result => {
         if (result.isConfirmed) {
           this.$store
             .dispatch(REMOVE_ARCHIVE, data)
             .then(res => {
               if (res.code == 200) {
                 this.$toastr.s(res.msg);
                 this.refreshData();
               }
             })
             .catch(errs => {
               this.errorMsg = errs.error;
               this.$toastr.e(errs.error);
             });
         }
       });
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    tablereset() {
      if (this.search.location == "all") {
        this.columns = [
          {
            name: "date",
            width: "",
            label: "Date",
            class: ""
          },

          {
            name: "enrollment_id",
            width: "",
            label: "Enr No",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "tcn",
            width: "",
            label: "TCN",
            class: ""
          },
          {
            name: "Service/location",
            width: "",
            label: "Service/Location",
            class: ""
          },

          {
            name: "archival_name",
            width: "",
            label: "Archival",
            class: ""
          },
          {
            name: "Expires on",
            width: "",
            label: "Expires on",
            class: ""
          }
        ];
        this.json_fields = {
          "Enroll Date": {
            field: "enroll_date",
            callback: value => {
              return this.dateFormat(value);
            }
          },
          "Applicant Name": "applicant",
          TCN: "tcn",
          Service: "service_name",
          Archival: "archival_name",
          "Expires On": {
            field: "archival_expiry_date",
            callback: value => {
              return this.dateFormat(value);
            }
          }
        };
      } else {
        this.json_fields = {
          "Enroll Date": {
            field: "enroll_date",
            callback: value => {
              return this.dateFormat(value);
            }
          },
          "Applicant Name": "applicant",
          TCN: "tcn",
          Location: "location_name",
          Service: "service_name",
          Archival: "archival_name",
          "Expires On": {
            field: "archival_expiry_date",
            callback: value => {
              return this.dateFormat(value);
            }
          }
        };
        this.columns = [
          {
            name: "date",
            width: "",
            label: "Date",
            class: ""
          },

          {
            name: "enrollment_id",
            width: "",
            label: "Enr No",
            class: ""
          },
          {
            name: "applicant",
            width: "",
            label: "Applicant",
            class: ""
          },
          {
            name: "tcn",
            width: "",
            label: "TCN",
            class: ""
          },

          {
            name: "archival_name",
            width: "",
            label: "Archival",
            class: ""
          },
          {
            name: "Expires on",
            width: "",
            label: "Expires on",
            class: ""
          }
        ];
      }
    },
    IndexClick(index) {
      if (this.showActionButton === index) {
        this.showActionButton = "";
      } else {
        this.showActionButton = index;
      }
    },
    advSearch(value) {
      this.search_text = "";
      if (this.search.no_of_days > 0) {
        let { header, search } = value;
        this.header = header;
        this.search = { ...search };
        this.appData();
      } else {
        this.$toastr.e("No of days should be greater than zero");
      }
    },

    closeEnrollmentModal() {
      this.enrollmentDetailModalShow = false;
    },
    handleDocumentClick(event) {
      const hasClass = event.target.classList.contains("modal-box");
      if (!hasClass) {
        // Call the Vue method using this
        this.IndexClick(-1);
      }
    },

    refreshData() {
      this.appData();
    },
    changeEnrollmentArchive(enroll_id, tcn, onsite,client_slug) {
      this.$router.push({
        name: "client.changearchive.enrollment",
        params: {
          enroll_id: enroll_id,
          tcn: tcn,
          onsite: onsite,
          client_slug:client_slug
        }
      });
    },

    openEnrollmentDetail(enrollmentId, index) {
      this.currentIndex = index;
      this.enrollmentDetailModalShow = true;
      this.selectedAppId = enrollmentId;
      this.loadingData = true;
      var data = {
        enrollment_id: enrollmentId
      };
      // console.log(data);
      this.$store.dispatch(ENROLLMENT_LOGS, {
        enrollment_id: enrollmentId
      });
      this.$store
        .dispatch(FETCH_ENROLLMENT, data)
        .then(res => {
          // console.log(res);
          this.enrollmentData = res.data;
          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;
        })
        .catch(() => {
          alert();
          this.loadingData = false;
        });
    },

    loadServices() {
      this.service = "all";

      this.$store.dispatch(FETCH_SERVICES, this.search.location);
    },
    appData() {
      this.appoinments = [];
      this.getLoadingData = true;
      //   console.log(this.archival_period);
      var data_ = {
        service_outlet:this.search?.sp_location,
        ...this.search,
        type: this.currentUser.u.is_client ? "c" : "sp"
      };

      this.$store

        .dispatch(FETCH_EXPIRY_ARCHIVAL_APPOINTMENT, data_)
        .then(res => {
          this.excelreports = [...res.data];
          this.getLoadingData = false;
          this.appoinments = res.data;

          // console.log(res);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>
